import { Link } from 'gatsby'
import React from "react"
// import { useReduceMotion } from "react-reduce-motion";
import { useScrollAnimation } from "../components/useScrollAnimation";
import { motion, useTransform } from "framer-motion";
import Layout from '../components/Layout'
import Seo from '../components/seo'
import { StaticImage } from "gatsby-plugin-image";
import BannerMp4 from '../videos/about.mp4'
import BannerWebm from '../videos/about.webm'
import BannerOgv from '../videos/about.ogv'
import BannerCover from '../videos/video_cover_what.jpg'
// import TechLogos from '../components/TechLogos'
import Icon from '@mdi/react'
import { mdiArrowRight } from '@mdi/js'

export default function Careers() {

    // const prefersReducedMotion = useReduceMotion();
    const prefersReducedMotion = false; //react-reduce-motion fails at build time due to window

    const [bannerRef, bannerScroll] = useScrollAnimation();
    const bannerScale = useTransform(bannerScroll, [0, 0.5, 1], prefersReducedMotion ? [1, 1, 1] : [1, 1, 1.2]);

    const [imgRef, imgScroll] = useScrollAnimation();
    const imgScale = useTransform(imgScroll, [0, 1], prefersReducedMotion ? [1, 1.1] : [1.2, 1]);


    return (
        <Layout>


            <Seo
                title='Seeking Software and Web Developers | New Zealand | Webfox'
                description='Join our development team here in Hawke’s Bay.'
            />


            {/* section1 */}
            <div ref={bannerRef}>
                <section className="layer intro intro-default dark">
                    <motion.video animate={{ scale: bannerScale.get() }} id="background-video" autoPlay loop muted playsInline poster={BannerCover}>
                        <source src={BannerWebm} type='video/webm' />
                        <source src={BannerMp4} type='video/mp4' />
                        <source src={BannerOgv} type='video/ogg' />
                    </motion.video>

                    <div className="inner">
                        <div className="copy">
                            <h1>Careers at Webfox</h1>
                        </div>
                    </div>
                </section>
            </div>

            {/* section 2 */}
            <section className="layer singlecol transparent">
                <div className="inner">
                    <h2>Want to help us push some boundaries?</h2>
                    <p>We’re team of problem solvers, visionaries and strategists. But we’re also geeks who love to stay hands-on with bringing big ideas to life with smart technology.</p>
                    <p>From our Hawke’s Bay development house, we build digital products for businesses right throughout New Zealand, and beyond.</p>
                </div>
            </section>
            {/* 
            <section className="layer singlecol transparent">
                <div ref={imgRef} className="inner">


                    <div className="background-zoom-image">

                        <motion.div animate={{ scale: img1Scale.get() }}>
                            <StaticImage
                                src="../images/team/devs-on-couch-laugh.jpg"
                                alt="Webfox developers socialising on break"
                                style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
                            />
                        </motion.div>
                    </div>

                </div>
            </section> */}

            <section className="layer double-text team-pic black-bg dark">
                <div className="inner">
                    <div ref={imgRef} className="background-zoom-image">
                        <motion.div animate={{ scale: imgScale.get() }}>

                            <StaticImage
                                src="../images/team/webfox-team-2022.jpg"
                                alt="The Webfox team"
                                style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
                            />
                        </motion.div>
                    </div>
                    <div className="grid col-2 align-top">
                        <div className="col">
                            <h2>Are you a likeminded developer that wants to create a bit of digital magic?</h2>
                        </div>
                        <div className="col">
                            <p>Team fit is paramount, so ideally you will be a driven solution focused developer who loves keeping up with the play and the new tech. Do you enjoy a bit of a laugh and like to build solid relationships? Because, even though we’re mostly made up of passionate developers, we’re sociable creatures too!</p>
                        </div>
                    </div>

                </div>
            </section>




            <section className="layer singlecol transparent">
                <div className="inner">

                    <h2>Career Opportunities</h2>
                    <p>We are currently looking for Web Application Developers to join our talented team. Ideally you’ll be experienced in developing web applications and have the following skills and experience at the intermediate level or higher:</p>
                    <ul>
                        <li>Excellent communication skills.</li>
                        <li>You can work closely in a team and can go solo when its coding time!</li>
                        <li>You like creating, you like prototyping, you like innovating</li>
                        <li>Your open to learning and sharing your learnings</li>
                        <li>3-5 years’ experience in PHP. Experience with Laravel would be even better!</li>
                        <li>React JS or Vue.... but mainly React. Either way strong with JavaScript</li>
                        <li>Strong skills in backend architecture</li>
                        <li>AWS and maybe a bit of Google Cloud</li>
                        <li>Experience with Docker and build tools</li>
                        <li>Experience developing PWA's or ReactNative apps would be great!</li>
                    </ul>

                    <p><Link to="/contact" className="button icon-right">If this sounds like you, please get in touch <Icon path={mdiArrowRight} size={1.3} /></Link></p>

                </div>
            </section>

            {/* <TechLogos /> */}

        </Layout>
    )
}
